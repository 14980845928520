<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input label="姓名" clearable v-model="searchParam.cusRealName" />
        <v-input label="昵称" clearable v-model="searchParam.cusNickName" />
        <v-input
          clearable
          label="手机号"
          :maxlength="11"
          v-model="searchParam.cusMobileNum"
        />
        <v-cascader
          label="服务地点"
          placeholder="请选择"
          :width="width"
          v-model="bizLabelId"
          :options="sectionOps"
          :props="props"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="发放积分" type="text" @click="toPoint(scope.row)" />
      </template>
    </v-list>

    <v-dialog
      title="提示"
      v-model="isdialog"
      @confirm="sendPoint"
      cancelTxt="取消"
      sureTxt="发放"
    >
      <form-panel
        ref="formPanel"
        :hasHeader="false"
        :footerShow="false"
        :form="form"
      >
        <el-form-item label="选择租户" v-if="tenantIdType.length != 0">
          <v-select
            clearable
            placeholder="请选择"
            :options="tenantIdType"
            v-model="form.tenantId"
          />
        </el-form-item>

        <el-form-item
          label="发放积分类型"
          :rules="[
            {
              required: true,
              message: '请选择发放积分类型',
              trigger: 'change',
            },
          ]"
          prop="recordType"
        >
          <v-select
            :width="width"
            clearable
            disabled
            placeholder="请选择"
            :options="pointTypeOps"
            v-model="form.recordType"
          />
        </el-form-item>
        <el-form-item
          label="积分"
          :rules="[
            {
              required: true,
              message: '请输入积分值',
              trigger: 'blur',
            },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="tokenPoint"
        >
          <v-input :width="width" v-model.number="form.tokenPoint" />
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import {
  getLabelUserTrackListUrl,
  getLabelTreeListUrl,
  getPoints,
} from "./api.js";
import { setPointTypeOps } from "./map.js";

export default {
  name: "userNumberList",
  data() {
    return {
      timer: null,
      searchParam: {
        cusRealName: "",
        cusNickName: "", // 昵称
        cusMobileNum: "", // 手机号
        bizLabelId: undefined, // 选择小区
      },
      tableUrl: getLabelUserTrackListUrl,
      headers: [
        {
          prop: "cusRealName",
          label: "姓名",
          // formatter: (row, prop) =>
          //   createAlinkVNode(this, row, prop, {
          //     text: row[prop],
          //     cb: () => this.toUserManageForm(row),
          //   }),
        },
        {
          prop: "cusNickName",
          label: "昵称",
          // formatter: (row, prop) =>
          //   createAlinkVNode(this, row, prop, {
          //     text: row[prop],
          //     cb: () => this.toUserManageForm(row),
          //   }),
        },
        {
          prop: "cusMobileNum",
          label: "手机号",
        },
        {
          prop: "cusSexName",
          label: "性别",
        },
        {
          prop: "matchCusLabelName",
          label: "符合条件用户标签",
        },
        {
          prop: "cusHouseName",
          label: "地址",
        },
        {
          prop: "matchBizLabelName",
          label: "服务地点",
        },
        {
          prop: "happenTime",
          label: "发生时间",
        },
      ],
      /* tree */
      width: 188,
      bizLabelId: [],
      sectionOps: [],
      props: {
        multiple: false,
        label: "name",
        value: "id",
        children: "children",
      },
      /** end */
      /* v-dialog */
      curItem: {},
      isdialog: false,
      pointTypeOps: setPointTypeOps(),
      form: {
        recordType: 26,
        tokenPoint: undefined,
        tenantId: "",
      },
    };
  },
  watch: {
    bizLabelId: {
      handler(val) {
        this.searchParam.bizLabelId = val[val.length - 1];
      },
      deep: true,
    },
  },
  computed: {
    tenantIdType() {
      if (
        this.$store.state.app.userInfo &&
        this.$store.state.app.userInfo.containTenants
      ) {
        let arr = this.$store.state.app.userInfo.containTenants.map((item) => {
          item.label = item.tenantName;
          item.value = item.tenantId;
          return item;
        });
        return arr;
      } else {
        return [];
      }
    },
  },

  created() {
    this.getLabelTreeList();
  },
  methods: {
    async getLabelTreeList() {
      let res = await this.$axios.get(getLabelTreeListUrl, {
        params: {
          labelType: 2,
        },
      });
      console.log(res);
      if (res.code === 200) {
        this.sectionOps = res.data;
      }
    },
    toPoint(item) {
      this.curItem = item;
      this.isdialog = true;
    },
    init() {
      this.form.tenantId = "";
      this.form.tokenPoint = "";
      this.form.recordType = 26;
    },
    sendPoint() {
      if (this.tenantIdType.length != 0) {
        if (!this.form.tenantId) {
          this.$message("请选择租户");
          return;
        }
      }

      //按照当前登录的账号去发放--老蔡
      let params = {
        tenantId:
          this.tenantIdType.length != 0
            ? this.form.tenantId
            : this.$store.state.app.userInfo.tenantId,
        userId: this.curItem.cusUserId,
        operationType: 1, // 1 表示加积分 2 表示扣积分
        ...this.form,
      };

      this.$axios.post(getPoints, params).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.isdialog = false;
          this.init();
        } else {
          let msg = res.msg || "发放积分失败";
          this.isdialog = false;
          this.$message(msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
